import { Button } from '@/components/Button';
import Layout from '@/components/Layout';
import PageHeader from '@/components/PageHeader';
import { Typography } from '@/components/Typography';
import { ArgoSingleIcon } from '@/static/assets/icons/Argo';
import ThankYouForContributionsImage from '@/static/assets/images/ArgoAppreciation/thank-you-for-contributions.png';
import { seoConfiguration } from '@/utils/seo-config';
import './argo-appreciation.scss';

const ArgoPeerAppreciationPage = () => {
  return (
    <Layout {...content.seo}>
      <div className="argo-appreciation-page">
        <PageHeader
          textPrimary={{
            text: content.header,
            size: 45
          }}
          textDescription={{
            text: content.subheader,
            color: 'black'
          }}
        />

        <section className="award">
          <div className="animation col-sm-8 col-md-5 col-lg-6">
            <img
              src={ThankYouForContributionsImage}
              className="appreciation by akuity for contributing to argo project"
            />
          </div>

          <div className="content col-sm-8 col-md-5 col-lg-5">
            <Typography
              component="h2"
              typographyKind="header-secondary-small-medium"
            >
              {content.award}
            </Typography>

            <ul>
              <Typography
                component="li"
                typographyKind="text-primary-large-bold"
              >
                Akuity Argo <span className="swag-text">SWAG</span> pack
              </Typography>

              <Typography
                component="li"
                typographyKind="text-primary-large-bold"
              >
                One Time GitHub sponsorship of $100
              </Typography>
            </ul>
          </div>
        </section>

        <section className="how">
          <Typography
            component="h3"
            typographyKind="header-secondary-large-bold"
          >
            How?
          </Typography>

          <Typography
            component="h4"
            typographyKind="header-secondary-small-medium"
          >
            The community can nominate individuals who they see as going above
            and beyond when it comes to contributing to and/or promoting the
            Argo Project. Click in the link below to nominate an Argonaut 🚀
          </Typography>

          <div className="nomination-btn-wrapper">
            <Button
              additionalClass="nomination-btn"
              link={content.nominationLink}
              isExternalLink
            >
              Nominate
            </Button>
          </div>
        </section>

        <div className="no-global-padding argocd-icon-wrapper">
          <ArgoSingleIcon />
        </div>

        <section className="who">
          <Typography
            component="h3"
            typographyKind="header-secondary-large-bold"
          >
            Who?
          </Typography>

          <Typography
            component="h4"
            typographyKind="header-secondary-small-medium"
          >
            The full list of winners appears below. You think someone's missing?{' '}
            <a
              href={content.nominationLink}
              target="__blank"
              className="nomination-link"
            >
              Nominate a colleague!
            </a>
          </Typography>
        </section>

        <section className="winners">
          <Typography
            component="h3"
            typographyKind="header-secondary-large-bold"
          >
            Winners 🎉
          </Typography>

          <div className="container">
            {content.winners.map((winner) => {
              const profileUrl = `https://github.com/${winner.github}`;
              return (
                <div className="winner-card" key={winner.github}>
                  <a href={profileUrl} target="_blank" rel="noreferrer">
                    <img
                      src={`https://github.com/${winner.github}.png`}
                      alt={`github profile picture of ${winner.github}`}
                      className="winner-avatar"
                    />
                  </a>
                  <Typography
                    link={profileUrl}
                    isExternalLink
                    component="a"
                    typographyKind="text-primary-medium-bold"
                    className="winner-github-profile"
                  >
                    {winner.github}
                  </Typography>
                  <Typography
                    component="p"
                    typographyKind="text-primary-medium-medium"
                    className="winner-note"
                  >
                    {winner.note}
                  </Typography>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </Layout>
  );
};

const content = {
  seo: seoConfiguration['argo-appreciation'],
  header: `Akuity Peer Appreciation Program for Argo`,
  subheader: `Let’s celebrate the Argo community by regularly awarding the highly active open source contributors that shape the project.`,
  award: 'Every quarter we will choose one individual and award them with:',
  nominationLink: 'https://share.hsforms.com/1TfygMF3gRgW_3_6uzis6dActmgv',
  winners: [
    {
      github: 'alexef',
      note: 'For making impressive efforts and putting a lot of work into Argo CD.'
    },
    {
      github: 'blakepettersson',
      note: 'For not being noisy about his work, yet hugely impactful.'
    },
    {
      github: 'ishitasequeira',
      note: 'For the willingness to take on big challenges that are absolutely crucial for the project.'
    },
    {
      github: 'reggie-k',
      note: 'For incredible persistence, showing a genuine concern for the community\'s experience, and always giving back.'
    },
    {
      github: '34fathombelow',
      note: 'For individual contributions that help keeping the Argo project healthy and readiness to volunteer for issues in the crunch.'
    },
    {
      github: 'tczhao',
      note: 'For being an Argo Workflows champion inside the company and constantly contributing to the project in his spare time.'
    },
    {
      github: 'Rwong2888',
      note: 'For being super-helpful to the Argo community and bringing issues that are always spot-on.'
    }
  ]
};

export default ArgoPeerAppreciationPage;
